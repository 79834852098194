require('../css/font.css');
require('../css/font-raleway.css');
require('../css/style.css');

window.addEventListener('DOMContentLoaded', run);

async function run(e) {

    document.body.addEventListener('click', e => {

        if (e.target.classList.contains('faq-btn') || e.target.classList.contains('faq-title')) {
            toggleFaq(e.target, {
                collapseOthers: true
            });

            if (isMobile()) {
                const toggleArea = document.querySelector('.faq-listing .m-slide-down');
                const wrapper = toggleArea.querySelector('.m-slide-down-measure');
                if (toggleArea.clientHeight > 0) {
                    toggleArea.style.height = wrapper.clientHeight + 'px';
                }
            }
        } else if (e.target.classList.contains('read-more')) {
            toggleReadMore(e.target, {
                parent: '.container',
                area: '.slide-down',
                wrapper: '.slide-down-measure',
                text: 'self'
            });
        } else if (e.target.classList.contains('read-more-2') || e.target.classList.contains('collapse-2')) {
            toggleReadMore(e.target, {
                parent: '.t-top-title',
                area: '.m-slide-down',
                wrapper: '.m-slide-down-measure',
                onCollapsed: (clicked, options) => {
                    setTimeout(() => {
                        clicked.classList.remove('m-visible');
                        clicked.classList.add('m-hidden');

                        const btnExpand = clicked.closest(options.parent).querySelector('.read-more-2');
                        btnExpand.closest('.m-readmore-btn-wrapper').querySelector('.dots').textContent = '...';
                        btnExpand.classList.remove('m-hidden');
                    }, 450);
                },
                onExpanded: (clicked, options) => {
                    clicked.classList.remove('m-visible');
                    clicked.classList.add('m-hidden');
                    clicked.closest('.m-readmore-btn-wrapper').querySelector('.dots').textContent = '.';
                    clicked.closest(options.parent).querySelector('.collapse-2').classList.remove('m-hidden');
                }
            });
        } else if (e.target.classList.contains('read-more-faq')) {
            toggleReadMore(e.target, {
                parent: '.faq-listing',
                area: '.m-slide-down',
                wrapper: '.m-slide-down-measure',
                text: 'self'
            });
        } else if (e.target.classList.contains('scroll-to-link')) {
            scrollTo(e);
        } else if (e.target.classList.contains('icon')) {
            toggleMobileMenu();
        }
    });

    window.addEventListener('resize', e => {
        const height = !isMobile() ? 'initial' : '0px';
        const mobileReadMoreAreas = Array.from(document.querySelectorAll('.m-slide-down'));
        mobileReadMoreAreas.forEach(area => area.style.height = height);
    });

    const slider = new Slider({
        parent: '.t-section',
        slideArea: '.tabs-wrapper',
        slides: '.mytabs',
        nav: '.m-slider-nav',
        navButtons: '.t-top-tabs',
        navButton: 'li'
    });

    if (slider.isMobile()) {
        slider.slideToActiveButton();
    }

    lazyLoadYoutube();
}


function scrollTo(e) {

    typeof e.preventDefault === 'function' && e.preventDefault();
    const link = e.target;
    const id = link.href.slice(link.href.lastIndexOf('#') + 1);
    const element = document.getElementById(id);
    // element.scrollIntoView({ behavior: 'smooth' });
    scrollToElement(element);

    if (link.dataset.tab) {
        document.getElementById(link.dataset.tab).click();
    }

    toggleMobileMenu(false);
}

function scrollToElement(element, behavior = 'smooth') {

    const scrollTop = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop
    const finalOffset = element.getBoundingClientRect().top + scrollTop;

    window.scrollTo({
        top: finalOffset,
        behavior
    });
}

function toggleMobileMenu(state) {

    const hasState = typeof state !== 'undefined';
    const header = document.querySelector('.site-header');
    const condition = hasState ? !!state : !header.classList.contains('nav-visible');

    condition
        ? header.classList.add('nav-visible')
        : header.classList.remove('nav-visible');
}

function toggleFaq(faqBtn, options = {}) {

    const faq = faqBtn.closest('.faq');
    const faqs = Array.from(faq.parentElement.children);
    const isLast = faqs.indexOf(faq) === faqs.length - 1;

    if (faq.classList.contains('collapsed')) {

        if (Object(options).collapseOthers) {
            const wrapper = faqBtn.closest('.faq-wrapper');
            const faqs = wrapper.querySelectorAll('.faq');
            if (faqs.length) {
                faqs.forEach(item => {
                    item.classList.remove('expanded');
                    item.classList.add('collapsed');
                });
            }
        }

        faq.classList.remove('collapsed');
        faq.classList.add('expanded');
    } else {
        faq.classList.remove('expanded');
        faq.classList.add('collapsed');
    }

    if (isLast) {
        // faq.scrollIntoView();
        scrollToElement(faq, 'auto');
    }
}

function toggleReadMore(clicked, options) {

    const toggleArea = clicked.closest(options.parent).querySelector(options.area);

    if (toggleArea.clientHeight) {
        toggleArea.style.height = 0;
        if (options.text) {
            const text = options.textCollapsed || 'Read More';
            options.text === 'self'
                ? clicked.textContent = text
                : clicked.querySelector(options.text).textContent = text;
        }
        if (options.image) clicked.querySelector(options.image).style.transform = 'rotate(0deg)';

        if (typeof options.onCollapsed === 'function') {
            options.onCollapsed.call(null, clicked, options);
        }
    } else {

        const wrapper = toggleArea.querySelector(options.wrapper || 'div');

        // Handle any nested read mores.
        // This will trigger when device is mobile and there is nested desktop read more.
        if (Array.isArray(options.nested)) {
            options.nested.forEach(selector => {
                const nestedAreas = wrapper.querySelectorAll(selector);
                if (nestedAreas.length) {
                    nestedAreas.forEach(nestedArea => nestedArea.style.height = 'auto');
                }
            });
        }

        toggleArea.style.height = wrapper.clientHeight + 'px';

        if (options.text) {
            const text = options.textExpanded || 'Collapse';
            options.text === 'self'
                ? clicked.textContent = text
                : clicked.querySelector(options.text).textContent = text;
        }

        if (options.image) clicked.querySelector(options.image).style.transform = 'rotate(180deg)';

        if (typeof options.onExpanded === 'function') {
            options.onExpanded.call(null, clicked, options);
        }
    }
}

function isMobile() {
    const detector = document.querySelector('.mobile-detector');
    const styles = window.getComputedStyle(detector);
    return styles.getPropertyValue('display') === 'none';
}

class Slider {

    constructor(params) {
        this.parent = document.querySelector(params.parent);
        this.slideArea = this.parent.querySelector(params.slideArea);
        this.slides = Array.from(this.parent.querySelectorAll(params.slides));
        this.nav = this.parent.querySelector(params.nav);
        this.navItems = Array.from(this.nav.querySelectorAll(params.navItems || 'li'));
        this.navButtonsParent = this.parent.querySelector(params.navButtons);
        this.navButtons = this.navButtonsParent ? Array.from(this.navButtonsParent.querySelectorAll(params.navButton || 'a')) : [];
        this.activeIndex = 0;
        this.touchstartX = 0;
        this.touchstartY = 0;
        this.touchendX = 0;
        this.touchendY = 0;

        this.init();
    }

    init() {

        window.addEventListener('resize', e => this.slideToActiveButton());

        this.slideArea.addEventListener('touchstart', (event) => {
            this.touchstartX = event.changedTouches[0].screenX;
            this.touchstartY = event.changedTouches[0].screenY;
        }, false);

        this.slideArea.addEventListener('touchend', (event) => {
            this.touchendX = event.changedTouches[0].screenX;
            this.touchendY = event.changedTouches[0].screenY;
            this.handleSwipe(event);
        }, false);

        this.navItems.forEach(item => {
            item.addEventListener('click', e => {
                const idx = this.navItems.indexOf(e.target);
                this.slideTo(idx);
                this.slideToActiveButton();
            });
        });

        if (this.navButtonsParent) {
            this.navButtonsParent.addEventListener('touchstart', (event) => {
                this.touchstartX = event.changedTouches[0].screenX;
                this.touchstartY = event.changedTouches[0].screenY;
            }, false);

            this.navButtonsParent.addEventListener('touchend', (event) => {
                this.touchendX = event.changedTouches[0].screenX;
                this.touchendY = event.changedTouches[0].screenY;
                this.handleSwipe(event);
            }, false);

            this.navButtons.forEach(item => {

                function disablePointerEvents(children) {
                    for (let i = 0, ii = children.length; i < ii; i++) {
                        const child = children[i];
                        child.style.pointerEvents = 'none';
                        if (child.children.length) {
                            disablePointerEvents(child.children);
                        }
                    }
                }

                disablePointerEvents(item.children);

                item.addEventListener('click', e => {
                    e.stopPropagation();
                    const idx = this.navButtons.indexOf(e.target);
                    this.slideTo(idx);
                    this.slideToActiveButton();
                }, {});
            });
        }
    }

    handleSwipe(event) {
        var caseCaptured = false;

        const {
            touchstartX,
            touchstartY,
            touchendX,
            touchendY
        } = this;

        if (touchendX < touchstartX && Math.abs(touchendX - touchstartX) > 30) {
            // Swipe left
            caseCaptured = true;
            if (this.activeIndex < this.slides.length - 1) {
                this.slideTo(this.activeIndex + 1);
                this.slideToActiveButton();
            }

        } else if (touchendX > touchstartX && Math.abs(touchendX - touchstartX) > 30) {
            // Swipe right
            caseCaptured = true;

            if (!this.activeIndex) {
                // If user swipes left and the slider is on the first image -> do nothing
                return;
            }

            this.slideTo(this.activeIndex - 1);
            this.slideToActiveButton();
        }

        if (caseCaptured) {
            event.preventDefault();
        }
    }

    slideTo(idx) {

        const reset = item => item.classList.remove('active');

        this.navItems.forEach(reset);
        this.navButtons.forEach(reset);
        this.slides.forEach(reset);

        this.slides[idx] && this.slides[idx].classList.add('active');
        this.navItems[idx] && this.navItems[idx].classList.add('active');
        this.navButtons[idx] && this.navButtons[idx].classList.add('active');
        this.activeIndex = idx;
    }

    slideToActiveButton() {

        if (!this.navButtons.length) {
            return;
        }

        if (!this.isMobile()) {
            const firstButton = this.navButtons[0];
            firstButton.style.removeProperty
                ? firstButton.style.removeProperty('margin-left')
                : firstButton.style.removeAttribute('margin-left')
            return;
        }

        const {
            navButtonsParent,
            navButtons
        } = this;

        setTimeout(() => {

            const activeItem = navButtons.find(x => x.classList.contains('active'));
            const activeIdx = navButtons.indexOf(activeItem);
            const activeItemW = activeItem.offsetWidth;
            const parentW = navButtonsParent.offsetWidth;
            const marginLeft = activeIdx === 0
                ? (parentW / 2 - activeItemW / 2).toFixed()
                : (function measureItemsBefore() {

                    let value = 0;
                    for (let i = 0; i < activeIdx; i++) {
                        value += navButtons[i].offsetWidth;
                    }

                    const margin = parseInt(window.getComputedStyle(navButtons[0]).marginRight) * 2;
                    const itemsWidth = value + (margin * (activeIdx - 1));
                    const parentWidth = navButtonsParent.offsetWidth / 2;
                    const x = parentWidth - (margin + (activeItemW / 2));

                    return -(itemsWidth - x);
                }());

            navButtons[0].style.marginLeft = marginLeft + 'px';
        }, 50);
    }

    isMobile() {
        const detector = document.querySelector('.mobile-detector');
        const styles = window.getComputedStyle(detector);
        return styles.getPropertyValue('display') === 'none';
    }
}